.project__container{
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    column-gap: 4rem;
}

.project__content{
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: var(--container-color);
    padding: 6rem 0 2rem 2.5rem;
    width: 21rem;
    border-radius: 1rem;
}



.project__icon{
    display: block;
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-1);
}

.project__title{
    display: block;
    margin-bottom: var(--mb-1);
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
}

.project__button{
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
}

.project__button-icon{
    font-size: 1rem;
    transition: 0.3s;
}

.project__status {
  position: absolute; 
  top: 4px; 
  left: 4px; 
  z-index: 1; 
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
}

.bg-success {
  background-color: #28a745;
}

.bg-danger {
  background-color: #dc3545;
}

.text-white {
  color: #ffffff !important;
}


@media screen and (max-width: 992px){
    .project__container{
        padding: 2rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px){
  
    body{
      margin: 0 0 var(--header-height) 0;
    }
    .section{
      padding: 2rem 0 4rem;
    }
  
    .section__subtitle{
      margin-bottom: var(--mb-3);
    }
  
  }
  
  /* For small devices */
  @media screen and (max-width: 576px){
    .project__container{
      grid-template-columns: max-content;
    }
    .project__content{
      padding-right: 6rem;
    }
  }





/* Modal visibility and transitions */
.projects__modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.active-modal{
  opacity: 1;
  visibility: visible;
}


.projects__modal-content{
  width: 500px;
  position: relative;
  background-color: var(--container-color);
  padding: 4.5rem 2.5rem  2.5rem;
  border-radius: 1.5rem;
}

.projects__modal-close{
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--title-color);
  cursor: pointer;
}


.projects__modal-title,
.projects__modal-description{
    text-align: center;
}

.projects__modal-title{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.projects__modal-description{
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}


.projects__modal-projects{
  row-gap: 0.75rem;
}

.projects__modal-service{
  display: flex;
  align-items: center;
  column-gap: .5rem;
}

.projects__modal-icon{
  color: var(--title-color);
  font-size: 1.1rem;
}

.projects__modal-info{
  font-size: var(--small-font-size);
}