.about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
}

.about__img {
    width: 350px;
    border-radius: 1.5rem;
    justify-self: center;
    height: 350px;
}

.about__button {
    align-items: center;
    appearance: none;
    background-color: #EEF2FF;
    border-radius: 8px;
    border-width: 2px;
    border-color: #536DFE;
    box-shadow: rgba(83, 109, 254, 0.2) 0 2px 4px, rgba(83, 109, 254, 0.15) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #536DFE;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 56px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 20px;
}

.about__button-icon {
    margin-left: 8px;
    font-size: 1.3rem;
}

.about__info {
    grid-template-columns: repeat(3, 140px);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}

.about__box {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__icon {
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-5);
}

.about__title {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.about__subtitle {
    font-size: var(--tiny-font-size);
}

.about__description {
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
}

.Lottie__contact {
    width: 370px;
    border-radius: 1.5rem;
    justify-self: center;
    height: 370px;
}

.make_disable {
    pointer-events: none;
    color: grey;
    cursor: not-allowed;
    text-decoration: none;
    opacity: 0.6;
}


/*================ BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .about__container {
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }

    .about__img {
        width: 220px;
    }

    .about__box {
        padding: 0.75rem 0.5rem;
    }

    .about__data {
        text-align: center;
    }

    .about__info {
        justify-content: center;
    }

    .about__description {
        padding: 0 5rem;
        margin-bottom: 2rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {}

@media screen and (max-width: 576px) {
    .about__info {
        grid-template-columns: repeat(3, 1fr);

    }

    .about__description {
        padding: 0;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .about__info {
        grid-template-columns: repeat(3, 1fr);
        margin-left: 30px;
    }


}