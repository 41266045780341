.skills__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Increased min-width */
    gap: 3rem;
    justify-content: center;
}

.skills__content {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 1.25rem;
    min-width: 400px; /* Ensures wider card width */
    transition: transform 0.3s ease;
}



.skills__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
}

.skills__box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem; 

}

.skills__group {
    display: flex;
    flex-direction: column;
    row-gap: 1rem; /* Space between skills */
    align-items: flex-start;
}

.skills__data {
    display: flex;
    align-items: center;  /* Aligns icon and text on the same line */
    gap: 0.5rem;
    white-space: nowrap; /* Prevents wrapping within each skill item */
}




.skills .bx-badge-check{
    font-size: 1rem;
    color: var(--title-color);
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 1.2; /* Adjusts line height for better alignment */
}


.skills__level{
    font-size: var(--tiny-font-size);
}


/*================ BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px){
    .skills__container{
        grid-template-columns: max-content;
        row-gap: 2rem;
    }
}
  
  /* For medium devices */
@media screen and (max-width: 768px){
    
}
  
@media screen and (max-width: 576px){
    .skills__container{
        grid-template-columns: 1fr;
    }

    .skills__content{
        padding: 1.5rem;
    }
}
  
/* For small devices */
@media screen and (max-width: 350px){
    .skills__box{
        column-gap: 1.25rem;
    }

    .skills__name{
        font-size: var(--small-font-size);
    }

    .skills__content{
        margin-left: 120px;
    }

    .section__title{
        margin-left: 100px;
    }

    .section__subtitle{
        margin-left: 110px;
    }
}